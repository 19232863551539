import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isMobile } from 'react-device-detect';

interface Props {
  modal: 'resend' | 'unregistered' | null;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: (props) => ({
    position: 'absolute',
    top: isMobile ? '' : '50%',
    left: '50%',
    transform: isMobile ? 'translate(-50%, 0%)' : 'translate(-50%, -50%)',
    width: props.modal === 'unregistered' ? 'fit-content' : '81.9vw',
    maxWidth: '515px',
    height: isMobile ? '292px' : 'auto',
    borderRadius: '16px',
    paddingBottom: '3px',
    boxSizing: 'content-box',
  }),
  logo: {
    height: 40,
    width: 40,
    alignSelf: 'center',
    marginTop: 16,
    objectFit: 'contain',
  },
  title: (props) => ({
    fontWeight: 600,
    fontSize: props.modal === 'unregistered' ? '20px' : 'clamp(20px, 5.6vw, 35px)',
    textAlign: 'center',
    lineHeight: '27px',
    marginTop: 16,
    padding: props.modal === 'unregistered' ? '0 21px 3px 21px' : 0,
    textWrap: 'nowrap',
  }),
  subtitle: (props) => ({
    fontWeight: 400,
    fontSize: props.modal === 'unregistered' ? '12px' : 'clamp(12px,  3.36vw, 18px)',
    textAlign: 'center',
    lineHeight: '18px',

    width: 'fit-content',
    alignSelf: 'center',
  }),
  phoneWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 6,
  },
  phoneNumber: (props) => ({
    alignSelf: 'center',

    '& .react-tel-input :disabled': {
      cursor: 'text',
    },
    '& .react-tel-input .selected-flag': {
      display: 'none',
    },
    '& .react-tel-input .form-control': {
      textAlign: props.modal === 'unregistered' ? 'center' : 'left',
      border: 'none',
      padding: '0px',
      width: props.modal === 'unregistered' ? '' : '118px',
      height: 'auto',
      fontSize: '12px',
      lineHeight: '1.5',
      letterSpacing: '0.02em',
      paddingLeft: 5,
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
    '& .react-tel-input .flag-dropdown': {
      display: 'none',
    },
  }),

  verificationCode: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& .ReactInputVerificationCode__item': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '48px',
      height: '48px',
      backgroundColor: '#E5E5E5',
      fontWeight: 400,
      fontSize: 36,
      border: 'none',
      boxShadow: 'none',
      color: theme.palette.primary.dark,
    },
    '& .is-active': {
      border: `3px solid #2D11AD`,
      boxShadow: 'none !important',
    },
    '& .ReactInputVerificationCode__container': {
      width: '212px',
    },
  },
  verificationCodeError: {
    '& .ReactInputVerificationCode__item': {
      border: `2px solid ${theme.palette.error.light}`,
    },
  },
  bottomMessage: {
    fontWeight: 400,
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    textAlign: 'center',
    lineHeight: 'clamp(18px,  3.9vw, 24px)',
    marginTop: 5,
    color: '#475467',
  },
  btnResend: {
    padding: 0,
    marginTop: '8px',
    marginLeft: 5,
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      transform: 'scale(0.95)',
    },
  },
  btnVerify: (props) => ({
    wordBreak: 'break-word',
    fontWeight: 600,
    margin: props.modal === 'unregistered' ? '28px auto 16px' : '22px auto 8px',
    fontSize: 'clamp(16px, 4.48vw, 24px)',
    width: '90%',
    maxWidth: '442px',
    height: 48,
    borderRadius: 16,
    backgroundColor: '#2D11AD',
    color: theme.palette.common.white,
    cursor: 'pointer',
    textTransform: 'none',
    padding: 0,
    '&:hover': {
      backgroundColor: '#2D11AD',
    },
    '&:active': {
      backgroundColor: '#2D11AD',
      transition: '0.3s',
      transform: 'scale(0.95)',
    },
    '&:disabled': {
      color: '#fff',
      background: '#ccc',
    },
  }),
  buttonBack: {
    fontWeight: 600,
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    color: theme.palette.common.black,
    '&:hover': {
      textDecoration: 'underline',
    },

    '&:active': {
      transition: '0.3s',
      transform: 'scale(0.95)',
    },
  },
  verificationError: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 'clamp(12px,  3.36vw, 18px)',
    color: theme.palette.error.light,
    marginTop: 3,
    marginBottom: 5,
  },

  unregisteredPhone: {
    marginBottom: 10,
    '& .react-tel-input .form-control': {
      color: `${theme.palette.error.light} !important`,
    },
  },
  btnVerifyUnregistered: {
    marginTop: '5px !important',
  },
}));
